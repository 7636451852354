import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { Box, Divider, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "simplebar/dist/simplebar.min.css";
import { CustomBackdrop } from "../../../../common/BackDrop/BackDrop";
import {
  CardHistories,
  CardProps,
} from "../../../../common/customCard/CustomCard";
import { CustomDatePickers } from "../../../../common/customDatePickers/CustomDate";
import { CustomSelect } from "../../../../common/customSelect/CustomSelect";
import {
  TEXT_HISTORIQUE,
  TRANSACTION,
  WALLET_SERVER,
} from "../../../../constant";
import {
  PERIODE_OPTIONS,
  // TRANSACTION_METHOD_LEGAL_PERSON,
  // TRANSACTION_METHOD_NATURAL_PERSON,
  TRANSACTION_METHOD_ADMIN,
  TRANSACTION_OPTIONS,
} from "../../../../constant/data/history";
import { GET_TRANSACTIONS } from "../../../../graphql/transactions/query";
import { OnHistoryUpdate } from "../../../../graphql/transactions/subscription";
import {
  onHistoryUpdate,
  onHistoryUpdateVariables,
} from "../../../../graphql/transactions/__generated__/onHistoryUpdate";
import {
  TRANSACTIONS,
  TRANSACTIONSVariables,
  TRANSACTIONS_transactions_data,
} from "../../../../graphql/transactions/__generated__/TRANSACTIONS";
import { getUser } from "../../../../provider/localesStorage";
import { TransactionMethod } from "../../../../types/graphql-global-types";
import {
  capitalizeFirstLetter,
  formatEndDateByView,
  formatStartDateByView,
  formatUsernameWithCountryCode,
} from "../../../../utils/helpers";
import Header from "../Header";
import { useStyles } from "./style";
import { UseHistory } from "./utils/useHistory";
import CustomAutoComplete from "../../../../common/CustomAutocomplette/CustomAutoComplete";
import { User_Options } from "../../../../graphql/autocompleteOptions/__generated__/User_Options";
import { USER_OPTIONS } from "../../../../graphql/autocompleteOptions/userOptions";
import { ALL_COIN_OPTIONS } from "../../../../graphql/autocompleteOptions/allCoinOptions";
import { AllCoinOptions } from "../../../../graphql/autocompleteOptions/__generated__/AllCoinOptions";

interface SelectOption {
  value: string;
  textName: string;
}

const initCoinOption: SelectOption = {
  value: "all",
  textName: "Toutes les valeurs",
};

export const Histories = () => {
  const [transactions, setTransactions] = useState<
    (TRANSACTIONS_transactions_data | null)[] | null
  >([]);
  const [totaltransaction, settotaltransaction] = useState<number>();
  const [scroll, setscroll] = useState<HTMLElement>();
  const [bigscroll, setBigscroll] = useState<HTMLElement>();
  const [page, setPage] = useState<number>(1);
  const [transactionNumber, setTransactionNumber] = useState<number>();
  const [userId, setUserId] = useState<any>();

  const [openDateAu, setOpenDateAu] = useState<boolean>(false);
  const [openDateDu, setOpenDateDu] = useState<boolean>(false);
  const {
    onChange,
    selectedDateAu,
    selectedDateDu,
    view,
    setView,
    formatDate,
    setFormatDate,
    state,
    setState,
    handleChange,
    viewsDatePickers,
    handleDateChangeDu,
    handleDateChangeAu,
    minDate,
  } = UseHistory(setOpenDateDu, setOpenDateAu);

  const Me = getUser();
  const style = useStyles();
  const take = 18;
  let skip = 0;

  const { data: coinsOptions } = useQuery<AllCoinOptions>(ALL_COIN_OPTIONS);

  const [getTransactions, { loading }] = useLazyQuery<
    TRANSACTIONS,
    TRANSACTIONSVariables
  >(GET_TRANSACTIONS, {
    variables: { args: { take: take, skip: skip } },
    onError: (errors) => {
      errors.graphQLErrors.map((error) => {
        console.log("error==>", error);
        return error;
      });
    },
    onCompleted: (data) => {
      if (data && data.transactions && data.transactions.data) {
        setTransactions(data.transactions.data);
      }
      if (data && data.transactions) {
        const total = Math.ceil((data.transactions.total || 1) / take);
        settotaltransaction(total);
        setTransactionNumber(data.transactions.total || 0);
      }
    },
  });

  const [listUsers, { data: options, loading: userLoading }] =
    useLazyQuery<User_Options>(USER_OPTIONS, {
      variables: {},
      onError: (error) => {
        error.graphQLErrors.map((error) => {
          console.log("error==>", error);
          return error;
        });
      },
    });

  useSubscription<onHistoryUpdate, onHistoryUpdateVariables>(OnHistoryUpdate, {
    variables: { address: Me.address },
    onSubscriptionData: (data) => {
      if (
        data &&
        data.subscriptionData &&
        data.subscriptionData.data &&
        data.subscriptionData.data.onHistoryUpdate
      ) {
        getTransactions({
          variables: {
            args: {
              transactionMethod:
                state.transactionMethod !== "all"
                  ? state.transactionMethod
                  : undefined,
              transactionType:
                state.transfert !== "all" ? String(state.transfert) : undefined,
              currency: state.valeur !== "all" ? state.valeur : undefined,
              startDate: date,
              endDate: datefin,
              take: take,
              skip: skip,
              userId: state.userId,
            },
          },
        });
        if (scroll) {
          scroll.scrollTop = 0;
        }
        if (bigscroll) {
          bigscroll.scrollTop = 0;
        }
      }
    },
  });

  const [stateminDate, setStateminDate] = useState<any>(selectedDateDu);
  const date = formatStartDateByView(viewsDatePickers, selectedDateDu);
  const datefin = formatEndDateByView(viewsDatePickers, selectedDateAu);

  useEffect(() => {
    getTransactions({
      variables: {
        args: { take: take, skip: skip, startDate: date, endDate: datefin },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Query filter
  useEffect(() => {
    let queryVariables: TRANSACTIONSVariables = {
      args: { take: take, skip: skip },
    };

    // Start date
    if (selectedDateDu) {
      queryVariables = {
        args: { ...queryVariables.args, startDate: String(date) },
      };
    }

    // End date
    if (selectedDateAu) {
      queryVariables = {
        args: { ...queryVariables.args, endDate: String(datefin) },
      };
    }

    // Type de valeur
    if (state && state.valeur) {
      queryVariables =
        state.valeur !== "all"
          ? {
              args: {
                ...queryVariables.args,
                currency: state.valeur,
              },
            }
          : {
              args: {
                ...queryVariables.args,
              },
            };
    }

    // Type de transfert (transaction)
    if (state && state.transfert) {
      queryVariables =
        state.transfert !== "all"
          ? {
              args: {
                ...queryVariables.args,
                transactionType: String(state.transfert),
              },
            }
          : {
              args: {
                ...queryVariables.args,
              },
            };
    }

    // Méthode de transfert
    if (state && state.transactionMethod) {
      queryVariables =
        state.transactionMethod !== "all"
          ? {
              args: {
                ...queryVariables.args,
                transactionMethod: state.transactionMethod as TransactionMethod,
              },
            }
          : {
              args: {
                ...queryVariables.args,
              },
            };
    }
    if (state && state.userId) {
      queryVariables = {
        args: {
          ...queryVariables.args,
          userId: state.userId,
        },
      };
    }
    getTransactions({ variables: queryVariables });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateDu, selectedDateAu, viewsDatePickers, state]);

  const handleChangePage = (_event: object, page: number) => {
    let queryVariableChangePage =
      state.valeur === "all"
        ? {
            transactionMethod:
              state.transactionMethod === "all"
                ? null
                : (state.transactionMethod as TransactionMethod),
            transactionType: String(
              state.transfert === "all" ? "" : state.transfert
            ),
          }
        : {
            transactionMethod:
              state.transactionMethod === "all"
                ? null
                : (state.transactionMethod as TransactionMethod),
            transactionType: String(
              state.transfert === "all" ? "" : state.transfert
            ),
            currency: state.valeur,
          };
    skip = (page - 1) * take;
    getTransactions({
      variables: {
        args: {
          ...queryVariableChangePage,
          startDate: date,
          endDate: datefin,
          take: take,
          skip: skip,
          userId: state.userId,
        },
      },
    });
    setPage(page);
  };

  useEffect(() => {
    if (viewsDatePickers === 1) {
      setFormatDate("yyyy");
      setView(["year"]);
    } else if (viewsDatePickers === 2) {
      setView(["month", "year"]);
      setFormatDate("MM/yyyy");
    } else if (viewsDatePickers === 3) {
      setFormatDate("dd/MM/yyyy");
      setView(["date"]);
    }

    if (minDate) {
      setStateminDate(minDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewsDatePickers, minDate]);

  useEffect(() => {
    if (transactions && transactions.length === 0) {
      settotaltransaction(0);
    }
  }, [transactions]);

  const defaultText = loading ? "Recherche en cours..." : "Aucune transaction";

  const totalTransactionResult = (totalTransaction: number | undefined) => {
    const transactionNumber = totalTransaction || 0;
    return `${transactionNumber} résultat${transactionNumber > 1 ? "s" : ""}`;
  };

  const VALUES_OPTIONS: SelectOption[] = useMemo(() => {
    return (
      coinsOptions?.allCoinOptions?.reduce(
        (acc: SelectOption[], cur) => {
          if (cur) {
            acc.push({
              value: cur.value || "",
              textName: cur.textName || "",
            });
          }
          return acc;
        },
        [{ ...initCoinOption }]
      ) || [{ ...initCoinOption }]
    );
  }, [coinsOptions]);

  const isOnOpen = () => {
    listUsers();
  };

  const onChangeAutoComplete = (_e: any, value: any) => {
    if (typeof value == "string" && options && options.userOptions) {
      const newValue = options.userOptions.find(
        (item) => item && item.value === value.trim()
      );
      if (newValue) {
        setState({
          ...state,
          userId: newValue.id ? newValue.id : "",
        });
        setUserId(newValue.value);
      } else {
        setState({
          ...state,
          userId: value.trim(),
        });
        value && setUserId(value.trim());
      }
    } else {
      setState({ ...state, userId: value.id });
      setUserId(value.value);
    }
  };

  return (
    <Box className={style.rootContainer}>
      <Header
        {...{
          titleheader: "Historique",
          subtitle: "Votre historique de transfert",
          texte: TEXT_HISTORIQUE,
        }}
      />
      <PerfectScrollbar
        containerRef={(ref: any) => {
          setBigscroll(ref);
        }}
        className="scrollBarWrap mainContentBoxWrapper mainHistoryContainer"
      >
        <Box className={style.mainContentBox}>
          <Box className={style.selectHeader}>
            <CustomSelect
              {...{
                placeholder: "Type de valeur ",
                name: "Type de valeur :",
                value: state && state.valeur,
                variant: "outlined",
                valueOption: VALUES_OPTIONS,
                subtextdefault: "Tous les Valeurs",
                className: clsx(style.select, style.selectValeur),
                onChange: handleChange,
                selectName: "valeur",
              }}
            />
            <CustomSelect
              {...{
                placeholder: "Sens de transactions ",
                name: "Sens de transactions :",
                variant: "outlined",
                valueOption: TRANSACTION_OPTIONS,
                subtextdefault: "Tous les Transactions",
                className: clsx(style.select, style.selectTransaction),
                selectName: "transfert",
                value: state && state.transfert,
                onChange: handleChange,
              }}
            />
            <CustomSelect
              {...{
                name: "Type de transactions :",
                placeholder: "Type de transactions ",
                variant: "outlined",
                // valueOption: Me.isAdmin? TRANSACTION_METHOD_ADMIN : Me.legalEntity? TRANSACTION_METHOD_LEGAL_PERSON : TRANSACTION_METHOD_NATURAL_PERSON,
                valueOption: TRANSACTION_METHOD_ADMIN,
                subtextdefault: "Tous les méthodes",
                className: clsx(style.select, style.selectTransaction),
                selectName: "transactionMethod",
                onChange: handleChange,
                value: state.transactionMethod || "",
              }}
            />
            <CustomSelect
              {...{
                name: "Période :",
                variant: "outlined",
                value: viewsDatePickers,
                valueOption: PERIODE_OPTIONS,
                className: clsx(style.select, style.selectPeriode),
                onChange: onChange,
                selectName: "periode",
              }}
            />
            <CustomDatePickers
              {...{
                allowKeyboardControl: true,
                open: openDateDu,
                name: "Du",
                format: formatDate,
                value: selectedDateDu,
                handleDateChange: handleDateChangeDu,
                views: view,
                disableFuture: true,
                onClick: () => {
                  setOpenDateDu(true);
                },
                onClose: () => {
                  setOpenDateDu(false);
                },
              }}
            />
            <CustomDatePickers
              {...{
                allowKeyboardControl: true,
                name: "Au",
                open: openDateAu,
                format: formatDate,
                value: selectedDateAu,
                handleDateChange: handleDateChangeAu,
                views: view,
                disableFuture: true,
                onClick: () => {
                  setOpenDateAu(true);
                },
                onClose: () => {
                  setOpenDateAu(false);
                },
                minDate: stateminDate,
              }}
            />
            <CustomAutoComplete
              id={"id"}
              onOpen={isOnOpen}
              loading={userLoading}
              className={clsx(style.select, style.autoComplete)}
              options={
                (options &&
                  options.userOptions &&
                  options.userOptions.length &&
                  options.userOptions.filter((uo) => uo !== null)) ||
                []
              }
              inputValue={userId}
              optionLabelKey="label"
              placeholder="Tous les utilisateurs"
              name="userRecipientId"
              onChange={(e, value) => onChangeAutoComplete(e, value)}
              inputLabel="Utilisateur :"
              inputLabelStyle={clsx(style.autoCompleteLabel)}
              optionStyle={clsx(style.optionStyle)}
            />
          </Box>
          <Divider {...{ className: style.divider }} />
          <Typography className={clsx(style.totalTransaction)}>
            {totalTransactionResult(transactionNumber)}
          </Typography>
          <PerfectScrollbar
            containerRef={(ref: any) => {
              setscroll(ref);
            }}
            className="scrollBarWrap historyWrapper "
          >
            <Box className={clsx(style.historyList)}>
              <Box className={style.historyInnerList}>
                {transactions && transactions.length > 0 ? (
                  transactions.map((item, index) => {
                    if (item && index < take) {
                      const label = item?.label ?? "";

                      let nameUser = "";
                      if (label === TRANSACTION.sentLabel) {
                        if (item.recipient) {
                          nameUser = formatUsernameWithCountryCode(
                            capitalizeFirstLetter(
                              `${item.recipient.userName || ""}`
                            )
                          );
                        } else {
                          nameUser = WALLET_SERVER;
                        }
                      } else {
                        if (item.sender) {
                          nameUser = formatUsernameWithCountryCode(
                            capitalizeFirstLetter(
                              `${item.sender.userName || ""}`
                            )
                          );
                        } else {
                          nameUser = WALLET_SERVER;
                        }
                      }

                      const date = item.createdAt
                        ? moment(Number(item?.createdAt)).format("DD MMMM YYYY")
                        : "";
                      const heure = item.createdAt
                        ? "à " +
                          moment(Number(item?.createdAt)).format("HH") +
                          "h " +
                          moment(Number(item?.createdAt)).format("mm") +
                          "min " +
                          moment(Number(item?.createdAt)).format("ss") +
                          "sec"
                        : "";

                      const transactionMethod =
                        item.transactionMethod || TransactionMethod.PAYER;

                      const isConversion =
                        item.transactionMethod === TransactionMethod.CONVERSION;

                      const cardProps: CardProps = {
                        credit: item.amount || 0,
                        date,
                        nameUser,
                        label,
                        heure,
                        transactionMethod,
                        code: item.code || "",
                        shortCode:
                          (isConversion
                            ? item.targetCoinShortCode
                            : item.shortCode) || "",
                        color:
                          (isConversion ? item.targetCoinColor : item.color) ||
                          "",
                        targetCode: item.targetCoinCode || "",
                        isConversion,
                      };

                      return <CardHistories key={index} {...cardProps} />;
                    } else return null;
                  })
                ) : (
                  <Box className={style.boxNoTransaction}>
                    <h2>{defaultText}</h2>
                  </Box>
                )}
              </Box>
            </Box>
          </PerfectScrollbar>
        </Box>
      </PerfectScrollbar>
      <Box className={style.navigation}>
        <Pagination
          page={page}
          count={totaltransaction}
          showFirstButton
          showLastButton
          onChange={handleChangePage}
        />
      </Box>
      <CustomBackdrop {...{ open: loading }} />
    </Box>
  );
};
