import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, { FC } from "react";
import { useStyles } from "./style";
// import { getUser } from "../../provider/localesStorage";

interface selectProps {
  variant?: "filled" | "outlined" | "standard";
  selectName?: string;
  name?: string;
  onChange?: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
    child?: React.ReactNode
  ) => void;
  value?: any;
  className?: string | undefined;
  valueOption: {
    value: number | string;
    textName: string;
    showDivider?: boolean;
  }[];
  subtextdefault?: string;
  vertical?: number | "bottom" | "top" | "center";
  horizontal?: number | "center" | "left" | "right";
  placeholder?: string;
}

export const CustomSelect: FC<selectProps> = ({
  name,
  variant,
  selectName,
  className,
  valueOption,
  onChange,
  value,
  vertical,
  horizontal,
  placeholder,
}) => {
  const style = useStyles();
  // const Me = getUser();
  return (
    <Box className={style.selectBoxItem}>
      {name && <Typography variant="h6">{name}</Typography>}
      <FormControl variant={variant} className={style.formControl}>
        <InputLabel
          id="demo-simple-select-outlined-label"
          style={{ backgroundColor: "white" }}
        >
          {name}
        </InputLabel>
        <Select
          placeholder={placeholder}
          labelId={name}
          onChange={onChange}
          className={className}
          id={name}
          label={selectName}
          name={selectName}
          value={value}
          IconComponent={KeyboardArrowDown}
          MenuProps={{
            getContentAnchorEl: null,
            classes: { paper: "MuiPopover-custom" },
            anchorOrigin: {
              vertical: vertical ? vertical : "bottom",
              horizontal: horizontal ? horizontal : "left",
            },
            style: {
              maxHeight: 500,
            },
          }}
        >
          {valueOption.map((element) =>
            element.showDivider ? (
              <MenuItem
                key={element.value}
                className={style.divider}
                style={{ padding: 0, fontSize: "12px" }}
                disabled
              >
                {/* {Me.isAdmin? `----------${element.textName}----------` : '------------------------'} */}
                {`----------${element.textName}----------`}
              </MenuItem>
            ) : (
              <MenuItem key={element.value} value={element.value}>
                {element.textName}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </Box>
  );
};
