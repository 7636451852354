import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
// import { generateShortTokenName } from './utils';

// Function to generate a random color
// export const generateRandomColor = (): string => {
//   const letters = '0123456789ABCDEF';
//   let color = '#';
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// };

// Function to generate a lighter shade of a color
export const generateLighterColor = (
  color: string,
  percent: number,
): string => {
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  r = Math.min(255, Math.floor(r + (255 - r) * percent));
  g = Math.min(255, Math.floor(g + (255 - g) * percent));
  b = Math.min(255, Math.floor(b + (255 - b) * percent));

  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
};

// Function to generate a darker shade of a color
export const generateDarkerColor = (color: string, percent: number): string => {
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  r = Math.max(0, Math.floor(r - r * percent));
  g = Math.max(0, Math.floor(g - g * percent));
  b = Math.max(0, Math.floor(b - b * percent));

  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
};

// Style of the component
const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '30%',
    padding: '8px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '65px',
    height: '65px',
  },
  small: {
    borderRadius: '15%',
    padding: '5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
  },
  pictTxt: {
    "& span": {
      display: "block",
    },
    "& .code": {
      fontSize: 10,
      fontWeight: 'bold',
      "@media (max-width: 575px)": {
        fontSize: 10,
      },
    },
  },
}));

interface PictogramProps {
  shortCode: string;
  color: string;
  isSmall?: boolean;
  isEuCoins: boolean;
}

const Pictogram: React.FC<PictogramProps> = ({ shortCode, color, isSmall = false, isEuCoins }) => {
  const classes = useStyles();

  // Generate a lighter color for the background
  const lightBackgroundColor = isEuCoins ? color : generateLighterColor(color, 0.5); // Lighten by 20%

  // Generate a darker color for the text
  const textColor = isEuCoins ? '#FFFFFF' : generateDarkerColor(color, 0.5); // Darken by 50%

  return (
    <Box
      className={isSmall ? classes.small : classes.root}
      style={{ backgroundColor: lightBackgroundColor }}
    >
      {isSmall ? (
        <Typography variant="body2" style={{ color: textColor }} component="p" className={classes.pictTxt}>
          <span className="code">{shortCode}</span>
        </Typography>
      ) : (
        <Typography variant="h6" style={{ color: textColor }}>
          {shortCode}
        </Typography>
      )}
    </Box>
  );
};

export default Pictogram;
