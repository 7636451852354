import { useLazyQuery } from "@apollo/client";
import { Box, Divider, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "simplebar/dist/simplebar.min.css";
import { UseArchive } from "./utils/useHistory";
import { StyledTableCell, StyledTableRow, useStyles } from "./style";
import { GET_ARCHIVE } from "../../../graphql/Archive/query";
import {
  Archive,
  Archive_affinityCurrenciesArchives_data,
  ArchiveVariables,
} from "../../../graphql/Archive/__generated__/Archive";
import {
  formatEndDateByView,
  formatStartDateByView,
} from "../../../utils/helpers";
import Header from "../acceuil/Header";
import { EU_COINS, TEXT_ARCHIVE } from "../../../constant";
import { CustomSelect } from "../../../common/customSelect/CustomSelect";
import {
  PERIODE_OPTIONS,
  PERSON_TYPE_OPTIONS,
} from "../../../constant/data/history";
import { CustomDatePickers } from "../../../common/customDatePickers/CustomDate";
import CustomAutoComplete from "../../../common/CustomAutocomplette/CustomAutoComplete";
import { CustomBackdrop } from "../../../common/BackDrop/BackDrop";
import moment from "moment";
import Pictogram from "../../../common/CustomPithogramme/Pithogramme";
import { USER_OPTIONS } from "../../../graphql/autocompleteOptions/userOptions";
import { User_Options } from "../../../graphql/autocompleteOptions/__generated__/User_Options";
import { COIN_ARCHIVE_OPTIONS } from "../../../graphql/autocompleteOptions/coinArchiveOption";
import { CoinArchiveOptions } from "../../../graphql/autocompleteOptions/__generated__/CoinArchiveOptions";

export const Archiving = () => {
  const [archives, setArchives] = useState<
    (Archive_affinityCurrenciesArchives_data | null)[] | null
  >([]);
  const [totalArchive, setTotalArchive] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [archiveNumber, setArchiveNumber] = useState<number>();
  const [coinId, setCoinId] = useState<any>();
  const [userId, setUserId] = useState<any>();

  const [openDateAu, setOpenDateAu] = useState<boolean>(false);
  const [openDateDu, setOpenDateDu] = useState<boolean>(false);
  const {
    onChange,
    selectedDateAu,
    selectedDateDu,
    view,
    setView,
    formatDate,
    setFormatDate,
    state,
    setState,
    handleChange,
    viewsDatePickers,
    handleDateChangeDu,
    handleDateChangeAu,
    minDate,
  } = UseArchive(setOpenDateDu, setOpenDateAu);

  const style = useStyles();
  const take = 18;
  let skip = 0;

  const [getArchive, { loading }] = useLazyQuery<Archive, ArchiveVariables>(
    GET_ARCHIVE,
    {
      variables: { args: { take: take, skip: skip } },
      onError: (errors) => {
        errors.graphQLErrors.map((error) => {
          console.log("error==>", error);
          return error;
        });
      },
      onCompleted: (data) => {
        if (
          data &&
          data.affinityCurrenciesArchives &&
          data.affinityCurrenciesArchives.data
        ) {
          setArchives(data.affinityCurrenciesArchives.data);
        }
        if (data && data.affinityCurrenciesArchives) {
          const total = Math.ceil(
            (data.affinityCurrenciesArchives.total || 1) / take
          );
          setTotalArchive(total);
          setArchiveNumber(data.affinityCurrenciesArchives.total || 0);
        }
      },
    }
  );

  const [getCoinOptions, { data: coinOptions, loading: coinLoading }] =
    useLazyQuery<CoinArchiveOptions>(COIN_ARCHIVE_OPTIONS, {
      variables: {},
      onError: (error) => {
        error.graphQLErrors.map((error) => {
          console.log("error==>", error);
          return error;
        });
      },
    });

  const [getUserOptions, { data: userOptions, loading: userLoading }] =
    useLazyQuery<User_Options>(USER_OPTIONS, {
      variables: {},
      onError: (error) => {
        error.graphQLErrors.map((error) => {
          console.log("error==>", error);
          return error;
        });
      },
    });

  const [stateminDate, setStateminDate] = useState<any>(selectedDateDu);
  const date = formatStartDateByView(viewsDatePickers, selectedDateDu);
  const datefin = formatEndDateByView(viewsDatePickers, selectedDateAu);

  useEffect(() => {
    getArchive({
      variables: {
        args: { take: take, skip: skip, startDate: date, endDate: datefin },
      },
    });
  }, []);

  // Query filter
  useEffect(() => {
    let queryVariables: ArchiveVariables = {
      args: { take: take, skip: skip },
    };

    // Start date
    if (selectedDateDu) {
      queryVariables = {
        args: { ...queryVariables.args, startDate: String(date) },
      };
    }

    // End date
    if (selectedDateAu) {
      queryVariables = {
        args: { ...queryVariables.args, endDate: String(datefin) },
      };
    }

    // Type de person
    if (state && state.personType) {
      queryVariables =
        state.personType !== "all"
          ? {
              args: {
                ...queryVariables.args,
                personType: String(state.personType),
              },
            }
          : {
              args: {
                ...queryVariables.args,
              },
            };
    }

    if (state && state.coinId) {
      queryVariables = {
        args: {
          ...queryVariables.args,
          coinId: state.coinId,
        },
      };
    }

    if (state && state.userId) {
      queryVariables = {
        args: {
          ...queryVariables.args,
          userId: state.userId,
        },
      };
    }
    getArchive({ variables: queryVariables });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateDu, selectedDateAu, viewsDatePickers, state]);

  const handleChangePage = (_event: object, page: number) => {
    skip = (page - 1) * take;
    getArchive({
      variables: {
        args: {
          personType: state.personType === "all" ? null : state.personType,
          startDate: date,
          endDate: datefin,
          take: take,
          skip: skip,
          coinId: state.coinId,
          userId: state.userId,
        },
      },
    });
    setPage(page);
  };

  useEffect(() => {
    if (viewsDatePickers === 1) {
      setFormatDate("yyyy");
      setView(["year"]);
    } else if (viewsDatePickers === 2) {
      setView(["month", "year"]);
      setFormatDate("MM/yyyy");
    } else if (viewsDatePickers === 3) {
      setFormatDate("dd/MM/yyyy");
      setView(["date"]);
    }

    if (minDate) {
      setStateminDate(minDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewsDatePickers, minDate]);

  useEffect(() => {
    if (archives && archives.length === 0) {
      setTotalArchive(0);
    }
  }, [archives]);

  const defaultText = loading ? "Recherche en Cours..." : "Aucune archive";

  const totalArchiveResult = (totalArchive: number | undefined) => {
    const archiveNumber = totalArchive || 0;
    return `${archiveNumber} résultat${archiveNumber > 1 ? "s" : ""}`;
  };

  const isOnCoinOpen = () => {
    getCoinOptions();
  };

  const isOnUserOpen = () => {
    getUserOptions();
  };

  const onChangeCoinAutoComplete = (_e: any, value: any) => {
    if (
      typeof value == "string" &&
      coinOptions &&
      coinOptions.coinArchiveOptions
    ) {
      const newValue = coinOptions.coinArchiveOptions.find(
        (item) => item && item.value === value.trim()
      );
      if (newValue) {
        setState({
          ...state,
          coinId: newValue.id ? newValue.id : "",
        });
        setCoinId(newValue.id);
      } else {
        setState({
          ...state,
          coinId: value.trim(),
        });
        value && setCoinId(value.trim());
      }
    } else {
      setState({ ...state, coinId: value.id });
      setCoinId(value.id);
    }
  };

  const onChangeUserAutoComplete = (_e: any, value: any) => {
    if (typeof value == "string" && userOptions && userOptions.userOptions) {
      const newValue = userOptions.userOptions.find(
        (item) => item && item.value === value.trim()
      );
      if (newValue) {
        setState({
          ...state,
          userId: newValue.id ? newValue.id : "",
        });
        setUserId(newValue.id);
      } else {
        setState({
          ...state,
          userId: value.trim(),
        });
        value && setUserId(value.trim());
      }
    } else {
      setState({ ...state, userId: value.id });
      setUserId(value.id);
    }
  };

  return (
    <Box className={style.rootContainer}>
      <Header
        {...{
          titleheader: "Archive",
          subtitle: "Archive des monnaies affinitaires",
          texte: TEXT_ARCHIVE,
        }}
      />
      <PerfectScrollbar className="scrollBarWrap mainContentBoxWrapper mainContainer">
        <Box className={style.mainContentBox}>
          <Box className={style.selectHeader}>
            <CustomAutoComplete
              id={"id"}
              onOpen={isOnCoinOpen}
              loading={coinLoading}
              className={clsx(style.select, style.autoComplete)}
              options={
                (coinOptions &&
                  coinOptions.coinArchiveOptions &&
                  coinOptions.coinArchiveOptions.length &&
                  coinOptions.coinArchiveOptions.filter((ac) => ac !== null)) ||
                []
              }
              inputValue={coinId}
              optionLabelKey="label"
              placeholder="Par nom de la monnaie..."
              name="coin"
              onChange={(e, value) => onChangeCoinAutoComplete(e, value)}
              inputLabel="Recherche :"
              inputLabelStyle={clsx(style.autoCompleteLabel)}
              optionStyle={clsx(style.optionStyle)}
            />
            <CustomAutoComplete
              id={"id"}
              onOpen={isOnUserOpen}
              loading={userLoading}
              className={clsx(style.select, style.autoComplete)}
              options={
                (userOptions &&
                  userOptions.userOptions &&
                  userOptions.userOptions.length &&
                  userOptions.userOptions.filter((uo) => uo !== null)) ||
                []
              }
              inputValue={userId}
              optionLabelKey="label"
              placeholder="Par personne physique ou morale..."
              name="user"
              onChange={(e, value) => onChangeUserAutoComplete(e, value)}
              inputLabel="Recherche :"
              inputLabelStyle={clsx(style.autoCompleteLabel)}
              optionStyle={clsx(style.optionStyle)}
            />
            <CustomSelect
              {...{
                name: "Type de personne :",
                placeholder: "Type de personne ",
                variant: "outlined",
                valueOption: PERSON_TYPE_OPTIONS,
                subtextdefault: "Tous les types",
                className: clsx(style.select, style.selectPersonType),
                selectName: "personType",
                onChange: handleChange,
                value: state.personType || "",
              }}
            />
            <CustomSelect
              {...{
                name: "Période :",
                variant: "outlined",
                value: viewsDatePickers,
                valueOption: PERIODE_OPTIONS,
                className: clsx(style.select, style.selectPeriode),
                onChange: onChange,
                selectName: "periode",
              }}
            />
            <CustomDatePickers
              {...{
                allowKeyboardControl: true,
                open: openDateDu,
                name: "Du",
                format: formatDate,
                value: selectedDateDu,
                handleDateChange: handleDateChangeDu,
                views: view,
                disableFuture: true,
                onClick: () => {
                  setOpenDateDu(true);
                },
                onClose: () => {
                  setOpenDateDu(false);
                },
              }}
            />
            <CustomDatePickers
              {...{
                allowKeyboardControl: true,
                name: "Au",
                open: openDateAu,
                format: formatDate,
                value: selectedDateAu,
                handleDateChange: handleDateChangeAu,
                views: view,
                disableFuture: true,
                onClick: () => {
                  setOpenDateAu(true);
                },
                onClose: () => {
                  setOpenDateAu(false);
                },
                minDate: stateminDate,
              }}
            />
          </Box>
          <Divider {...{ className: style.divider }} />
          <Typography className={clsx(style.totalTransaction)}>
            {totalArchiveResult(archiveNumber)}
          </Typography>
          <PerfectScrollbar className="scrollBarWrap historyWrapper">
            <Box className={clsx(style.historyList)}>
              <Box className={style.historyInnerList}>
                {archives && archives.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={style.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Nom de la monnaie</StyledTableCell>
                          <StyledTableCell>Créateur</StyledTableCell>
                          <StyledTableCell>Type</StyledTableCell>
                          <StyledTableCell>Date de création</StyledTableCell>
                          <StyledTableCell align="right">
                            Montant payé à la création
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Nombre de validations
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Montant payé pour validation
                          </StyledTableCell>
                          <StyledTableCell>
                            Date de mise en circulation
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Nbr de versement
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Montant du versement
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Date de la disparition
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {archives.map((archive) => (
                          <StyledTableRow key={archive?.id || ""}>
                            <StyledTableCell component="th" scope="archive">
                              <Box className={style.object}>
                                <Pictogram
                                  shortCode={archive?.shortCode || ""}
                                  color={archive?.color || "#4B7FD5"}
                                  isSmall={true}
                                  isEuCoins={archive?.coinName === EU_COINS}
                                />
                                <Typography
                                  variant="body2"
                                  component="p"
                                  className={style.info_txt}
                                >
                                  <span className="code">
                                    {archive?.coinName}
                                  </span>
                                </Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                              {archive?.creator}
                            </StyledTableCell>
                            <StyledTableCell>{archive?.type}</StyledTableCell>
                            <StyledTableCell>
                              {moment(Number(archive?.coinCreatedAt)).format(
                                "DD-MM-YYYY-HH:mm"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {archive?.creationPaidAmount}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {archive?.numberOfValidations}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {archive?.validationPaidAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(Number(archive?.createdAt)).format(
                                "DD-MM-YYYY-HH:mm"
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {archive?.numberOfPayments}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {archive?.paymentAmount}
                            </StyledTableCell>
                            <StyledTableCell>
                              {archive && archive.deadAt
                                ? moment(Number(archive.deadAt)).format(
                                    "DD-MM-YYYY-HH:mm"
                                  )
                                : null}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box className={style.boxNoTransaction}>
                    <h2>{defaultText}</h2>
                  </Box>
                )}
              </Box>
            </Box>
          </PerfectScrollbar>
        </Box>
      </PerfectScrollbar>
      <Box className={style.navigation}>
        <Pagination
          page={page}
          count={totalArchive}
          showFirstButton
          showLastButton
          onChange={handleChangePage}
        />
      </Box>
      <CustomBackdrop {...{ open: loading }} />
    </Box>
  );
};
