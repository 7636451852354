import { useQuery, useSubscription } from "@apollo/client";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { CustomBackdrop } from "../../../../common/BackDrop/BackDrop";
import { TEXT_CONSULTATION } from "../../../../constant";
import { TRANSFERT_URL } from "../../../../constant/url";
import { DO_GET_BALANCE } from "../../../../graphql/Balances";
import { OnBalanceUpdate } from "../../../../graphql/Balances/subscription";
import {
  GET_BALANCE,
  GET_BALANCE_balances,
} from "../../../../graphql/Balances/__generated__/GET_BALANCE";
import {
  onBalanceUpdate,
  onBalanceUpdateVariables,
  onBalanceUpdate_onBalanceUpdate_balance,
} from "../../../../graphql/Balances/__generated__/onBalanceUpdate";
import { getUser } from "../../../../provider/localesStorage";
import Header from "../Header";
import Cart from "./Cart";
import { useStyles } from "./style";
import { BalanceContext } from "../../../../provider/Balanceprovider";
import { CustomModal } from "../../../../common/customModal/CustomModal";

interface IModalStatus {
  open: boolean;
  message: string;
}

export const Balances = () => {
  const Me = getUser();
  const style = useStyles();
  const [resultData, setResultData] = useState<
    (GET_BALANCE_balances | null)[] | null
  >();
  const { setBalance } = useContext(BalanceContext);
  const [modalStatus, setModalStatus] = useState<IModalStatus>({
    open: false,
    message: ''
  });
  const history = useHistory();
  const { loading: loadingCurrency } = useQuery<
    GET_BALANCE,
    GET_BALANCE_balances
  >(DO_GET_BALANCE, {
    onCompleted: (data) => {
      if (data && data.balances) {
        setResultData(data.balances);
      }
    },
  });

  useEffect(() => {
    if(resultData) {
      setBalance(resultData);
      const coinShouldDead = resultData.filter((coin) => coin?.shouldDead);
      if (coinShouldDead.length) {
        const plural = coinShouldDead.length > 1;
        const money = plural ? "ces monnaies" : "cette monnaie";
        const does = plural ? "les fera" : "la fera";
        const coins = coinShouldDead.map((coin) => coin?.code).join(', ');
        const message = `Vous êtes le dernier utilisateur de ${money} : ${coins}. Vous êtes invité à convertir le montant de ${money} en EU-coins ce qui ${does} disparaître définitivement !`;
        setModalStatus({
          open: true,
          message,
        })
      } else {
        setModalStatus({
          open: false,
          message: '',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultData]);

  useSubscription<onBalanceUpdate, onBalanceUpdateVariables>(OnBalanceUpdate, {
    variables: { address: Me?.address || "" },
    onSubscriptionData: (data) => {
      if (
        data &&
        data.subscriptionData &&
        data.subscriptionData.data &&
        data.subscriptionData.data.onBalanceUpdate
      ) {
        setResultData(
          data.subscriptionData.data.onBalanceUpdate
            .balance as (onBalanceUpdate_onBalanceUpdate_balance | null)[]
        );
      }
    },
  });

  const handleClick = (id: any) => {
    history.push(TRANSFERT_URL + `${id}`);
  };

  const defaultText = loadingCurrency
    ? "Recherche en cours..."
    : "Aucun résultat trouvé";

  return (
    <Box className={style.rootContainer}>
      <CustomModal 
        open={modalStatus.open} 
        setOpen={(open) => setModalStatus((prev) => ({
          ...prev,
          open,
        }))} 
        maxWidth="sm"
      >
        <Box>{modalStatus.message}</Box>
      </CustomModal>
      <Header
        {...{
          titleheader: "Accueil",
          subtitle: "Votre liste de valeurs",
          texte: TEXT_CONSULTATION,
          withUserId: true,
        }}
      />

      <SimpleBar className="scrollBarWrap transfertWrap mainBalanceContainer" forceVisible="y">
        <Box className={clsx(style.transfert_list)}>
          <Box className={style.transfert_innerlist}>
            {resultData && resultData?.length ? (
              resultData.map((item, index): any => {
                return (
                  <Cart
                    key={`${item?.value}-${index}`}
                    style={style}
                    devise={item?.currency || ""}
                    value={item?.value || ""}
                    handleClick={() => handleClick(item?.currency)}
                    balance={item}
                  />
                );
              })
            ) : (
              <Box className={style.boxNoTransaction}>
                <h2>{defaultText}</h2>
              </Box>
            )}
          </Box>
        </Box>
      </SimpleBar>
      <CustomBackdrop {...{ open: loadingCurrency }} />
    </Box>
  );
};
