import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useStyles } from './style';
import CardNotice from '../../../../common/cardeNotice/CardNotice';
import { TEXT_CREATION, TEXT_VALIDATION_MORAL_IMPOSSIBLE } from '../../../../constant/TextNotice/TextNotice';
import { useMutation } from '@apollo/client';
import { CREATE_COIN } from '../../../../graphql/CreateCoin/mutation';
import {
  createCoin,
  createCoinVariables,
} from '../../../../graphql/CreateCoin/__generated__/createCoin';
import { CustomSnackBar } from '../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../common/BackDrop/BackDrop';
import { CustomModal } from '../../../../common/customModal/CustomModal';
import { setting } from '../../../../graphql/Setting/__generated__/setting';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../provider/Authprovider';


const CreationPM: React.FC<{data?: setting}> = ({data}) => {
  const style = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  // State
  const [isSuccess, setIsSuccess] = useState(false);
  const [monnaie, setMonnaie] = useState('');
  const [message, setMessage] = useState({
    modal: '',
    toast: ''
  });
  const history = useHistory();
  const { onShowSnackBar } = useContext(AuthContext);
  const [type, setType] = useState<string>('');
  const [open, setOpen] = useState({
    modal: false,
    toast: false
  });

  // Mutation
  const [Docreate, { loading: loadingCreate }] = useMutation<
    createCoin,
    createCoinVariables
  >(CREATE_COIN, {
    onCompleted: (data) => {
      const creationData = data.createCoin.transactionHistories?.pop();
      const transactionHash = creationData?.transaction_hash ?? '';
      onShowSnackBar(
        `Votre transfert a été réalisé avec succès sous le Nº ${transactionHash}`,
        'success',
        true,
        5000
      );
      setMessage(prev => ({...prev, modal: 'Création réussie !'}));
      setOpen(prev => ({...prev, modal: true}));
      setIsSuccess(true);
    },
    onError: (errors) => {
      setMessage(prev => ({...prev, modal: errors.message, toast: errors.message}));
      setType('error');
      setOpen(prev => ({...prev, modal: true, toast: true}));
    },
  });
  
  // Handlers
  const handleClick = () => {
    if (monnaie === '' || monnaie !== monnaie.trim()) {
      const mes = monnaie === '' ? 'Veuillez entrer le nom de la monnaie.' : "Désolé, il est impossible de placer un ou plusieurs espaces au début ou à la fin du nom d'une monnaie affinitaire. Veuillez éliminer s'il vous plaît tout espace en début et en fin de nom !";
      setType('error');
      setMessage({
        modal: mes,
        toast: mes,
      });
      setOpen({
        modal: true,
        toast: true
      });
    } else {
      setMessage({
        modal: '',
        toast: '',
      });
      Docreate({
        variables: {
          createCoinInput: {
            code: monnaie,
          },
        },
      });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonnaie(e.target.value);
  };

  const NOTE_LEGAL_PERSON = TEXT_CREATION.replace(/{VALIDATOR}/g,data?.setting?.numberOfValidatorsLegalEntity?.toString() ?? '').split(/{COST}|{EU-}/);

  return (
    <Box className={style.transferContainer}>
      <CustomModal
        open={open.modal}
        setOpen={(value) => {
          setOpen(prev => ({
            ...prev,
            modal: value,
          }));
          if(isSuccess) {
            history.push('/');
          }
        }}
        maxWidth="sm"
      >
        <Box>{message.modal}</Box>
      </CustomModal>
      <Box className={style.containtWrappe}>
        <Box className={style.cardNotice}>
          <CardNotice width="80%">
           {TEXT_VALIDATION_MORAL_IMPOSSIBLE}
          </CardNotice> 
        </Box><br />
        <Typography className={style.textPM}>
          Je suis une personne morale (société, association, administration,
          etc...) et l'initiateur de <br />
          cette création de monnaie. Je demande la conversion de
        </Typography>

        <Box className={style.flexContainer}>
          <Typography>
            <span className={style.typography}>{data?.setting?.amountOfCreationCostLegalEntity} EU-coins</span> en{' '}
            <span className={style.typography}>{data?.setting?.amountOfCreationCostLegalEntity} EU-</span>
          </Typography>

          <Box>
            <TextField
              className={style.textField}
              type="text"
              name="monnaie"
              label="Nom de la monnaie"
              variant="outlined"
              value={monnaie}
              onChange={handleInputChange}
            />
          </Box>
        </Box>

        <Typography className={style.textPM}>
          et leur transfert vers le compte du gestionnaire des monnaies "4MP"
          (prestataire agréé).
        </Typography>

        <Box className={style.buttonContainer}>
          <ThemeProvider theme={theme}>
            <Button
              onClick={handleClick}
              color="primary"
              variant="contained"
              size="large"
              className={style.btnConfirmer}
              disabled={loadingCreate}
            >
              {loadingCreate ? 'Transfert en cours...' : 'TRANSFERT/CRÉATION'}
            </Button>
          </ThemeProvider>
        </Box>

        <Box className={style.cardNotice}>
          <CardNotice width="80%">
            {NOTE_LEGAL_PERSON[0]}
            <span className={style.codeCurrency}>EU-{monnaie!=='' ? monnaie : 'xxx'}</span>
            {NOTE_LEGAL_PERSON[1]}
            <span className={style.codeCurrency}>[ {data?.setting?.amountOfCreationCostLegalEntity} ] EU-coins</span>
            {NOTE_LEGAL_PERSON[2]}
          </CardNotice> 
        </Box>

        <CustomSnackBar
          {...{ open: open.toast, setOpen: (value) => {
          setOpen(prev => ({...prev, toast: value}))
          }, message: message.toast, type: type }}
        />

        <CustomBackdrop open={loadingCreate} />
      </Box>
    </Box>
  );
};

export default CreationPM;
