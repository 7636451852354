import gql from "graphql-tag";

export const DO_SIGNUP = gql`
  mutation signUp($signUpInput: signUpInput) {
    signUp(signUpInput: $signUpInput) {
      status
      message
      data {
        id
        password
        name
        originName
      }
    }
  }
`;
