import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    transfertContainer: {
      marginTop: 40,
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      maxWidth: 1200,
      margin: '20px auto 0',
      padding: '20px 32px',
      width: 'calc(100% - 40px)',
      '& *': {
        boxSizing: 'border-box',
      },
    },
    bodyTransfert: {
      height: 'calc(100vh - 125px)',
      '@media (max-height: 640px)': {
        //height: "90vh",
      },
    },
    confirmDialog: {
      '& .MuiDialog-paper': {
        margin: 0,
        padding: 24,
      },
      '& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root':
        {
          padding: 0,
        },
      '& .MuiDialogTitle-root': {
        marginBottom: 20,
      },
      '& .MuiIconButton-root': {
        marginRight: -12,
        marginTop: -12,
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 507,
        '@media (max-width: 374px)': {
          maxWidth: 'calc(100% - 20px)',
        },
      },
    },
    transfertHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px 17px',
    },
    logo: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    textTypography: {
      height: 50,
      width: 50,
      backgroundColor: ' #4B7FD5',
      borderRadius: 10,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      transform: 'rotate(45deg)',
      color: 'white',
    },
    mainTitre: {
      color: '#B48A4E',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 24,
      lineHeight: '24px',
      margin: '0 0 18px',
      textAlign: 'center',
      '@media (max-width: 767px)': {
        margin: '0 0 10px',
      },
    },
    subTitre: {
      color: '#212226',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      margin: '1px 49px 36px',
      textAlign: 'center',
      '& strong': {
        fontWeight: 500,
      },
    },
    legend: {
      position: 'relative',
      color: '#212226',
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 8
    },
    asterisk: {
      position: 'absolute',
      color: 'black', 
      top: -5,
      fontSize:28, 
      fontWeight: 'bold'
    },
    boxLegend: {
      marginBottom: 15
    },
    fieldItem: {
      fontFamily: 'Poppins, sans-serif',
      marginBottom: 24,
      width: '100%',
      '@media (max-width: 575px)': {
        marginBottom: 12,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiOutlinedInput-input': {
        padding: 14,
      },
      '& .MuiInputBase-input, .MuiTypography-colorTextSecondary': {
        color: '#686B78',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 12,
        letterSpacing: '0.4px',
        lineHeight: '100%',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover': {
          borderColor: '#B48A4E',
          borderWidth: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B48A4E',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#B48A4E',
            borderWidth: 1,
          },
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E9EAEC',
        borderWidth: 1,
        '&:hover': {
          borderColor: '#B48A4E',
        },
      },
    },
    labeled: {
      color: '#212226',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
      marginBottom: 0,
    },
    radioListItem: {
      marginBottom: 20,
    },
    styleRadio: {
      color: '#212226',
      fontSize: 16,
      lineHeight: '24px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      '& .MuiFormControlLabel-root': {
        flex: '0 0 27%',
        margin: '0 13px 13px 0',
        '@media (max-width: 575px)': {
          width: '100%',
          margin: '0 0 10px 0',
        },
        '&:nth-child(3n)': {
          marginRight: '0',
        },
      },
      '& .MuiFormControlLabel-label': {
        color: '#212226',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        '@media (max-width: 575px)': {
          fontSize: 14,
        },
      },
      '& .MuiIconButton-root': {
        padding: '10px 12px',
        '@media (max-width: 479px)': {
          padding: '10px 8px',
        },
      },
      '& .MuiRadio-colorSecondary': {
        '&.Mui-checked': {
          color: '#B48A4E',
        },
      },
      '& .MuiFormControlLabel-root:nth-last-child(2)': {
        marginLeft: '0px',
      },
      '& .MuiFormControlLabel-root:nth-last-child(2):nth-child(odd)': {
        marginRight: 'auto',
      },
    },
    styleRadioPP: {
      color: '#212226',
      fontSize: 16,
      lineHeight: '24px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      marginRight: '10px',
      '& .MuiFormControlLabel-root': {
        flex: '0 0 30%',
        margin: '0 13px 13px 0',
        '@media (max-width: 575px)': {
          width: '100%',
          margin: '0 0 10px 0',
        },
        '&:nth-of-type(3n)': {
          marginRight: '0',
        },
        '&:nth-of-type(3n+1)': {
          clear: 'left',
        },
      },
      '& .MuiFormControlLabel-label': {
        color: '#212226',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        '@media (max-width: 575px)': {
          fontSize: 14,
        },
      },
      '& .MuiIconButton-root': {
        padding: '10px 12px',
        '@media (max-width: 479px)': {
          padding: '10px 8px',
        },
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#B48A4E',
      },
      '& .MuiFormControlLabel-root:nth-child(14)': {
        marginRight: 'auto',
      },
    },

    makeBold: {
      fontWeight: 'bold',
    },

    textField: {
      height: 42,
      fontSize: 14,
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 8,
      },
    },
    btnconnect: {
      boxShadow: 'none',
      borderRadius: 4,
      display: 'block',
      fontSize: 15,
      height: 42,
      lineHeight: '16px',
      margin: '0 auto',
      width: 316,
    },
    success: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    successContent: {
      textAlign: 'center',
    },
    iconIndicator: {
      lineHeight: '100%',
      margin: '0 0 10px',
      textAlign: 'center',
    },
    textSuccess: {
      color: '#02C39A',
      fontSize: 24,
      letterSpacing: 0.18,
      lineHeight: '24px',
      marginBottom: 5,
      textAlign: 'center',
    },
    textEchec: {
      color: '#F35F83',
      fontSize: 24,
      letterSpacing: 0.18,
      lineHeight: '24px',
      marginBottom: 5,
      textAlign: 'center',
    },
    msgSuccess: {
      color: '#212226',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      marginBottom: 23,
      maxWidth: 494,
      padding: '0 15px',
    },
    btnBack: {
      fontSize: 14,
      lineHeight: '16px',
      height: 48,
      margin: '0 auto',
      width: 327,
      '@media (max-width: 575px)': {
        width: '100%',
        maxWidth: 'calc(100% - 40px)',
      },
    },
    logoC4W: {
      height: 50,
      width: 50,
    },
    cardItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      boxShadow: 'none',
      height: 90,
      padding: '10px 20px',
      justifyContent: 'space-between',
      '@media (max-width: 575px)': {
        flexDirection: 'column',
        justifyContent: 'center',
        height: 'auto',
        padding: '10px',
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& .MuiPaper-rounded': {
        borderRadius: 6,
      },
    },
    cardContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      '@media (max-width: 575px)': {
        marginBottom: 10,
      },
      gap: 10,
    },
    logocontaint: {
      width: 50,
      minWidth: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: 15,
      marginRight: 18,
      '@media (max-width: 575px)': {
        marginRight: 14,
        width: 40,
        minWidth: 40,
        height: 40,
      },
    },
    info_txt: {
      '& span': {
        display: 'block',
      },
      '& .devise': {
        color: '#686B78',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: '24px',
        '@media (max-width: 575px)': {
          fontSize: 12,
        },
      },
      '& .value': {
        color: '#212226',
        fontSize: 20,
        lineHeight: '24px',
        fontWeight: 600,
        '@media (max-width: 575px)': {
          fontSize: 16,
        },
      },
    },
    actionBloc: {
      display: 'none',
    },
    imglogo: {
      width: 50,
      height: 50,
      border: '1px solid #8170DA',
      borderRadius: 16,
    },
   
  }),
);
