import { createStyles, makeStyles, Theme } from '@material-ui/core';
//import { isWhiteSpaceLike } from "typescript";

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      // height: '100vh',
      // minHeight: '100%',
      margin: 0,
      padding: '22px 28px 0',
      width: '100%',
      '@media (max-width: 1023px)': {
        height: '100vh',
        padding: '12px 14px 0',
      },
      '@media (max-height: 767px)': {
        //overflowY: "auto",
      },

      '& .mainContentBoxWrapper': {
        '&.scrollBarWrap': {
          height: 'calc(100vh - 250px)',
        },
        '& > .simplebar-track.simplebar-horizontal': {
          display: 'none',
        },
        '@media (min-height: 800px)': {
          '& > .simplebar-track': {
            '&.simplebar-vertical': {
              display: 'none',
            },
          },
          '& > .simplebar-placeholder': {
            height: 'auto !important',
            display: 'none',
          },
          '& > .simplebar-mask, .simplebar-offset': {
            '@media (max-height: 699px)': {
              position: 'static',
            },
          },
        },
        '&.ps': {
          '& .ps__rail-x': {
            display: 'none',
          },
          '& .ps__rail-y': {
            '@media (min-height: 700px)': {
              height: 'calc(100vh - 220px)',
            },
          },
        },
      },
    },
    transfertContainer: {
      marginTop: 40,
      border: '1px solid #E9EAEC',
      borderRadius: 6,
      height: 'auto',
      maxHeight: 560,
      maxWidth: 797,
      margin: '20px auto 0',
      padding: '20px 32px',
      width: 'calc(100% - 40px)',
      '@media (min-height: 768px)': {
        height: 560,
        margin: '40px auto 0',
        padding: '40px 65px',
      },
      '@media (max-width: 1023px)': {
        height: 560,
        maxHeight: 'initial',
        padding: '20px 32px',
      },
      '@media (max-width: 767px)': {
        margin: '20px auto 0',
        padding: '10px 16px',
      },
      '@media (max-width: 575px)': {},
      '& *': {
        boxSizing: 'border-box',
      },
    },
    cardContainer: {
      display: 'flex',
      gap: 20,
      flexDirection: 'row',
      width: '100%',
      marginTop: 180,
      '@media (max-width: 1440px)': {
        flexDirection: 'column',
        width: '100%',
      },
     '@media (max-width: 1080px)': {
        flexDirection: 'column',
        width: '100%',
        marginTop: 190,
      },
    },
    card: {
      width: '50%',
      overflow: 'hidden',
      '@media (max-width: 1440px)': {
        width: '100%',
      },
      boxShadow: 'none',
    },
    cardContent: {
      padding: _theme.spacing(2),
      border: '2px solid #B48A4E',
      borderRadius: '8px',
    },
    typography: {
      color: "#B48A4E",
      fontSize: 18,
      fontFamily: "Poppins, sans-serif",
      fontWeight: 600,
      "@media (max-width: 575px)": {
        fontSize: 16,
      },
      marginBottom: 2
    },
  }),
);
