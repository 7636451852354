import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  createMuiTheme,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../style';
import CardNotice from '../../../../../common/cardeNotice/CardNotice';
import { TEXT_PERSON_PHYSIQUE_VALIDATION } from '../../../../../constant/TextNotice/TextNotice';
import { CustomModal } from '../../../../../common/customModal/CustomModal';
import { useMutation } from '@apollo/client';
import { VALIDATE_COIN } from '../../../../../graphql/validateCoin/mutation';
import {
  validateCoin,
  validateCoinVariables,
} from '../../../../../graphql/validateCoin/__generated__/validateCoin';
import { CustomSnackBar } from '../../../../../common/CustomSnackBar/CustomSnackBar';
import { CustomBackdrop } from '../../../../../common/BackDrop/BackDrop';
import { setting } from '../../../../../graphql/Setting/__generated__/setting';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../../provider/Authprovider';

const CreationPPValidation:  React.FC<{data?: setting}> = ({data}) => {
  const style = useStyles();
  const [isSuccess, setIsSuccess] = useState(false);
  const [monnaie, setMonnaie] = useState('');
    const [message, setMessage] = useState({
      modal: '',
      toast: ''
    });
  const history = useHistory();
  const { onShowSnackBar } = useContext(AuthContext);
  const [type, setType] = useState<string>('');
  const [open, setOpen] = useState({
    modal: false,
    toast: false
  });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#B48A4E',
      },
    },
  });

  const [Dovalidate, { loading: loadingValidate }] =
    useMutation<validateCoin, validateCoinVariables>(VALIDATE_COIN, {
      onCompleted: (data) => {
        const transaction_hash = data.validateCoin.transaction_hash ?? '';
        onShowSnackBar(
          `Votre transfert a été réalisé avec succès sous le Nº ${transaction_hash}`,
          'success',
          true,
          5000
        );
        setMessage(prev => ({...prev, modal: 'Validation réussie !'}));
        setOpen(prev => ({...prev, modal: true}));
        setIsSuccess(true)
      },
      onError: (errors) => {
        setMessage(prev => ({...prev, modal: errors.message, toast: errors.message}));
        setType('error');
        setOpen(prev => ({...prev, modal: true, toast: true}));
      },
    });

  const handleClick = () => {
    if (monnaie === '' || monnaie.trim() !== monnaie) {
      const mes = monnaie === '' ? 'Veuillez entrer le nom de la monnaie.' : "Désolé, il est impossible de placer un ou plusieurs espaces au début ou à la fin du nom d'une monnaie affinitaire. Veuillez éliminer s'il vous plaît tout espace en début et en fin de nom !";
      setType('error');
      setMessage({
        modal: mes,
        toast: mes,
      });
      setOpen({
        modal: true,
        toast: true
      });
    } else {
      setMessage({
        modal: '',
        toast: '',
      });
      Dovalidate({
        variables: {
          validateCoinInput: {
            coinCode: monnaie,
          },
        },
      });
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMonnaie(e.target.value);
  };

  const NOTE_NATURAL_PERSON = TEXT_PERSON_PHYSIQUE_VALIDATION.replace(/{VALIDATOR}/g,data?.setting?.numberOfValidatorsNaturalPerson?.toString() ?? '').split('{COST}');

  return (
    <>
      <CustomModal
        open={open.modal}
        setOpen={(value) => {
          setOpen(prev => ({
            ...prev,
            modal: value,
          }));
          if(isSuccess){
            history.push('/');
          }
        }}
        maxWidth="sm"
      >
        <Box>
          {message.modal}
        </Box>
      </CustomModal>
      <Typography className={style.textPP}>
        Je suis une personne physique (citoyen) et l'un des validateurs de cette création de monnaie. Je demande la conversion de{' '}
      </Typography>
      <Box className={style.flexContainer}>
        <Typography>
          <span className={style.typography}>{data?.setting?.amountOfValidationCost} EU-coin</span> en{' '}
          <span className={style.typography}>{data?.setting?.amountOfValidationCost} EU-</span>
        </Typography>
        <Box>
          <TextField
            className={style.textField}
            type="text"
            name="monnaie"
            label="Nom de la monnaie"
            variant="outlined"
            value={monnaie}
            onChange={handleInputChange}
          />
        </Box>
      </Box>
      <Typography className={style.textPP}>
        et leur transfert vers le compte du gestionnaire des monnaies
        "4MP" (prestataire agréé).
      </Typography>
      <Box className={style.buttonContainer}>
        <ThemeProvider theme={theme}>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            size="large"
            className={style.btnConfirmer}
            disabled={loadingValidate}
          >
            {loadingValidate
              ? 'Validation en cours...'
              : 'TRANSFERT/VALIDATION'}
          </Button>
        </ThemeProvider>
      </Box>
      <Box className={style.cardNotice}>
        <CardNotice width="80%">
        {NOTE_NATURAL_PERSON[0]}
        <span className={style.codeCurrency}>[&nbsp;{data?.setting?.amountOfValidationCost}&nbsp;] EU-coin</span>
        {NOTE_NATURAL_PERSON[1]}
        </CardNotice>
      </Box>
      <CustomSnackBar
        {...{ open: open.toast, setOpen: (value) => {
          setOpen(prev => ({...prev, toast: value}))
        }, message: message.toast, type: type }}
      />
      <CustomBackdrop {...{ open: loadingValidate }} />
    </>
  );
};

export default CreationPPValidation;
